.nav-footer {
	@extend %nav-bg;
	.nav-footer-buttons {

		> li {
			> a {
				color: $nav-footer-link-color;
			}
		}
	}
}

.nav-function-fixed {

	.nav-footer {

		background: $nav-background;

		&:before {
			background: rgba($nav-title-border-bottom-color, 0.2);
			background: -moz-linear-gradient(left, $nav-background 0%, lighten($nav-background, 15%) 50%, lighten($nav-background, 15%) 50%, $nav-background 100%);
			background: -webkit-linear-gradient(left, $nav-background 0%, lighten($nav-background, 15%) 50%, lighten($nav-background, 15%) 50%, $nav-background 100%);
			background: linear-gradient(to right, $nav-background 0%, lighten($nav-background, 15%) 50%, lighten($nav-background, 15%) 50%, $nav-background 100%);
		}

	}

}

@include media-breakpoint-up(lg) {

	.nav-function-minify {

		.nav-footer {

			background-color: darken($nav-background, 2%);

			[data-class="nav-function-minify"] {
				color: $nav-icon-color;
			}

			&:hover {
				background-color: lighten($nav-background, 3%);

				[data-class="nav-function-minify"] {
					color: $nav-icon-hover-color;
				}
			}
		}
	}
}
