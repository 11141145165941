.datepicker table tr td.old, 
.datepicker table tr td.new {
	color: lighten($fusion-50, 10%);
}

.datepicker table tr td.active:active, 
.datepicker table tr td.active.highlighted:active, 
.datepicker table tr td.active.active, 
.datepicker table tr td.active.highlighted.active,
.datepicker table tr td.selected,
.datepicker table tr td.selected.highlighted,
.datepicker table tr td span.active.active,
.datepicker table tr td span.focused {
	background-color: $primary-400;
	border-color: $primary-500;
	color: $white;
}


.datepicker table tr td.active:active:hover, 
.datepicker table tr td.active.highlighted:active:hover, 
.datepicker table tr td.active.active:hover, 
.datepicker table tr td.active.highlighted.active:hover, 
.datepicker table tr td.active:active:focus, 
.datepicker table tr td.active.highlighted:active:focus, 
.datepicker table tr td.active.active:focus, 
.datepicker table tr td.active.highlighted.active:focus, 
.datepicker table tr td.active:active.focus, 
.datepicker table tr td.active.highlighted:active.focus, 
.datepicker table tr td.active.active.focus, 
.datepicker table tr td.active.highlighted.active.focus,
.datepicker table tr td.selected:active:hover, 
.datepicker table tr td.selected.highlighted:active:hover, 
.datepicker table tr td.selected.active:hover, 
.datepicker table tr td.selected.highlighted.active:hover,
.datepicker table tr td.selected:active:focus, 
.datepicker table tr td.selected.highlighted:active:focus, 
.datepicker table tr td.selected.active:focus, 
.datepicker table tr td.selected.highlighted.active:focus, 
.datepicker table tr td.selected:active.focus, 
.datepicker table tr td.selected.highlighted:active.focus, 
.datepicker table tr td.selected.active.focus, 
.datepicker table tr td.selected.highlighted.active.focus,
.datepicker table tr td.selected:hover, 
.datepicker table tr td.selected.highlighted:hover {
	background-color: $primary-600;
	border-color: $primary-700;
	color: $white;
}

.datepicker.datepicker-inline {
    border: 1px solid #ebedf2;
}

.datepicker thead th.prev, .datepicker thead th.datepicker-switch, .datepicker thead th.next {
    color: #a1a8c3;
}