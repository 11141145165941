
@mixin bg-variant($parent, $color, $ignore-warning: false) {
	#{$parent} {
		background-color: $color !important;
	}
	a#{$parent},
	button#{$parent} {
		@include hover-focus() {
			background-color: darken($color, 10%) !important;
		}
	}
}

@mixin bg-gradient-variant($parent, $color) {
	#{$parent} {
		background: $color linear-gradient(180deg, mix($body-bg, $color, 15%), $color) repeat-x !important;
	}
}


@mixin text-emphasis-variant($parent, $color, $ignore-warning: false) {
	#{$parent} {
		color: $color !important;
	}
	@if $emphasized-link-hover-darken-percentage != 0 {
		a#{$parent} {
			@include hover-focus() {
				color: darken($color, $emphasized-link-hover-darken-percentage) !important;
			}
		}
	}
}


//bootstrap button colors
@each $color, $value in $theme-colors {
	.btn-#{$color} {
		@include button-variant($value, $value);
	}
}

@each $color, $value in $theme-colors {
	.btn-outline-#{$color} {
		@include button-outline-variant($value);
	}
}

//border
@each $color, $value in $theme-colors {
	.border-#{$color} {
		border-color: $value !important;
	}
}

// Typography
@each $color, $value in $theme-colors {
	@include text-emphasis-variant(".text-#{$color}", $value);
}

//bg varient
@each $color, $value in $theme-colors {
	@include bg-variant(".bg-#{$color}", $value);
}

@if $enable-gradients {
	@each $color, $value in $theme-colors {
		@include bg-gradient-variant(".bg-gradient-#{$color}", $value);
	}
}

//root colors
:root {
	@each $theme-colors, $value in $theme-colors {
		--theme-#{$theme-colors}: #{$value};
	}

	@each $theme-colors-extended, $value in $theme-colors-extended {
		--theme-#{$theme-colors-extended}: #{$value};
	}
}